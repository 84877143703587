import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const AboutPageTemplate = ({image, title, content, contentComponent}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div classname="tile is-ancestor">
                <div classname="tile is-vertical">
                  <div classname="tile">
                    <div classname="tile is-parent is-vertical">
                      <article classname="tile is-child">
                        <PreviewCompatibleImage imageInfo={image} />
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout>
      <AboutPageTemplate
        image={post.frontmatter.image}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

// AboutPage.propTypes = {
//   data: PropTypes.object.isRequired,
// }

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}


export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
          markdownRemark(id: {eq: $id }) {
          html
      frontmatter {
          title
        image {
          childImageSharp {
          fluid(maxWidth: 526, quality: 92) {
          ...GatsbyImageSharpFluid
        }
          }
        }
      }
    }
  }
`
